export const getFeatureZoom = (urlParams) => {
    let mapViewSettings = [-114.83479406614642, 55.581467716870236, 5];

    if (urlParams != null) {
        mapViewSettings = [urlParams.lon, urlParams.lat, 7];
        window.history.pushState({}, document.title, "/");
        return mapViewSettings;

    } else {
        window.history.pushState({}, document.title, "/");
        return mapViewSettings;
    }
};

export const highlightFeaturePopup = (view, layer, field, fieldId) => {
    view.whenLayerView(layer).then(function (layerView) {
        layerView.watch("updating", function (value) {
            if (!value) {
                layerView.queryFeatures({
                    outFields: layerView.availableFields,
                    where: `${field} = '${fieldId}'`

                }).then(function (results) {
                    let attributes;
                    let graphics = results.features;

                    if (graphics.length > 0) {
                        attributes = graphics[0].attributes;
                        layerView.highlight(graphics);

                        view.popup.open({
                            features: graphics,
                            location: {
                                latitude: attributes.latitude,
                                longitude: attributes.longitude
                            }
                        });
                    };

                }).catch(function (error) {
                    console.error("query failed: ", error);
                });
            };
        });
    });
};


import { Flex, Image, useTheme } from "@aws-amplify/ui-react";

import logo from '../logos/logoWhiteBg.jpg'

export function Header() {
  const { tokens } = useTheme();

  return (
    <Flex justifyContent="center">
      <Image
        alt="HazardWatch"
        src={logo}
        padding={tokens.space.xxxl}
      />
    </Flex>
  );
}

import { Flex, useAuthenticator, Link, Image } from "@aws-amplify/ui-react";

import logo from '../logos/logoWhiteTransparent.png'

export function Navbar(signOut, user) {
  ({ signOut, user } = useAuthenticator());

  return (
    <Flex justifyContent={'center'}>
      <div id='navbar'>
        <div id='navbar-left'>
          <Link href="https://www.hazardwatch.ca/">
            <Image
              src={logo}
              alt='HazardWatch'
            />
          </Link>
        </div>
        <div id='navbar-right'>
          <Flex justifyContent={'right'} height={'100%'}>
            <div id='greeting-container'>
              <h1>Hello, {user.username}</h1>
            </div>
            <div id='btn-container'>
              <div
                id='sign-out-btn'
                className="esri-icon-sign-out esri-widget--button"
                onClick={signOut}
                title="Sign Out"
              />
            </div>
          </Flex>
        </div>
      </div>
    </Flex>
  );
}

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:4744417f-bf9f-4ec6-8742-7046a7f39c47",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_eybqWWhNf",
    "aws_user_pools_web_client_id": "5656d5v87j88o34f0i9cacmje9",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": "15",
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "hazNotificationsAPI",
            "endpoint": "https://l8i0aczd0l.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        }
    ]
};


export default awsmobile;

import { Fragment } from 'react';
import { Authenticator } from "@aws-amplify/ui-react";

import { Header } from "./Header";
import { Footer } from "./Footer";
import { SignInHeader } from "./SignInHeader";
import { SignInFooter } from "./SignInFooter";
import { Navbar } from './Navbar';
import { Table } from './Table';

import Map from './Map';

const components = {
  Header,
  SignIn: {
    Header: SignInHeader,
    Footer: SignInFooter,
  },
  Footer
};
export function Login() {
  return (
    <Authenticator components={components}>
      {({ signOut, user }) => (
        <Fragment>
          <Navbar signOut={signOut} user={user} />
          <Map />
          <Table />
        </Fragment>
      )}
    </Authenticator>
  );
}

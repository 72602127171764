import { Flex, Link, useAuthenticator, useTheme } from "@aws-amplify/ui-react";

export function SignInFooter() {
  const { toResetPassword } = useAuthenticator();
  const { tokens } = useTheme();

  return (
    <div>
      <div>
        <Flex justifyContent="center" padding={`0 0 ${tokens.space.medium}`}>
          <Link onClick={toResetPassword}>Reset your password</Link>
        </Flex>
      </div>
      <div>
        <Flex justifyContent="center" padding={`0 0 ${tokens.space.medium}`}>
          <p>New Here? <Link href="https://www.hazardwatch.ca/">Visit Hazard Watch</Link></p>
        </Flex>
      </div>
    </div>
  );
}

import './App.css';
import { Login } from "./components/Login";

import { AmplifyProvider } from "@aws-amplify/ui-react";

export default function App() {
  return (
    <AmplifyProvider>
      <Login />
    </AmplifyProvider>
  );
}

import FeatureTable from "@arcgis/core/widgets/FeatureTable";

export const configureFeatureTable = (view, activeHazardLayer) => {
    const featureTable = new FeatureTable({
        view,
        layer: activeHazardLayer,
        fieldConfigs: [
            {
                name: "hazard_label",
                label: 'Hazard Label',
                visible: true,
            },
            {
                name: "status",
                label: 'Status',
                visible: true,
            },
            {
                name: "latitude",
                label: 'Latitude',
                visible: true,
            },
            {
                name: "longitude",
                label: 'Longitude',
                visible: true,
            },
            {
                name: "proximity_to_area",
                label: 'Proximity to AoI (Km)',
                visible: true,
            },
            {
                name: "aoi_name",
                label: 'AoI',
                visible: true,
            },
        ],
        container: document.getElementById("feature-table"),
        label: 'Hazards'
    });

    view.whenLayerView(activeHazardLayer).then(function (layerView) {
        layerView.watch("updating", function (value) {
            if (!value) {
                featureTable.refresh();
            }
        });
    });
};
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import CIMSymbol from "@arcgis/core/symbols/CIMSymbol";

export const configureActiveHazardLayer = () => {
    const activeHazardRenderer = {
        type: "unique-value",
        field: "status",
        defaultSymbol: {
            type: "simple-marker",
            size: 7,
            color: [20, 20, 20]
        },
        uniqueValueInfos: [
            {
                value: "Out of Control",
                symbol: {
                    type: "simple-marker",
                    size: 7,
                    color: "red"
                }
            },
            {
                value: "Being Held",
                symbol: {
                    type: "simple-marker",
                    size: 7,
                    color: "yellow"
                }
            },
            {
                value: "Under Control",
                symbol: {
                    type: "simple-marker",
                    size: 7,
                    color: [91, 194, 54]
                }
            },
            {
                value: "Assistance Started",
                symbol: {
                    type: "simple-marker",
                    size: 7,
                    color: [174, 198, 207]
                }
            },
            {
                value: "New",
                symbol: {
                    type: "simple-marker",
                    size: 7,
                    color: "orange"
                }
            },
            {
                value: "Fire of Note",
                symbol: new CIMSymbol({
                    data: {
                        type: "CIMSymbolReference",
                        symbol: {
                            "type": "CIMPointSymbol",
                            "symbolLayers": [
                                {
                                    "type": "CIMPictureMarker",
                                    "enable": true,
                                    "anchorPoint": {
                                        "x": 0,
                                        "y": 0,
                                        "z": 0
                                    },
                                    "anchorPointUnits": "Relative",
                                    "dominantSizeAxis3D": "Y",
                                    "size": 12,
                                    "billboardMode3D": "FaceNearPlane",
                                    "invertBackfaceTexture": true,
                                    "scaleX": 1,
                                    "textureFilter": "Picture",
                                    "tintColor": [
                                        255,
                                        255,
                                        255,
                                        255
                                    ],
                                    "url": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAaCAYAAAC6nQw6AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAABcvAAAXLwG1k46fAAAAB3RJTUUH4QQDEzcJAUJuEAAAA19JREFUOMuF1U9sVFUUBvDfu+/NTGemMy102lJoS6VQS6EqCoYqmhiNkiiaQKJbFho3xhW6MLo1rEyMiSsTt7pwR9xowkISWagYiEbcCEqpC1uMBaZO/zwXc6cg4c9N7uLlnPOd7/vOyX2Ju5y5GZDhcXyPq0Pf3j43uPfZhmexfLek7B5s4AWMYuUOOQkq2U0fOdxCvRuHMH8HkBKewmJH2jQatyE2KfGQoGZV8RaQHryLvfihI+0BPIMPbpJEbka3XqsGLKlKNWO8juOx7mU0O4yW8Hpk1gHJJB7Ti8Sg1ECMFPA2juJTzN48tVaczqtII1C/zLRerOkTTMTcF/EmLuHrjq8doGIEPbLOKrdN2bAacsXoxRDekqhJ/CI31xHQAeqNk9sSNcOUbnWVGGkv5WtSe/Uh+NOvWrcCjUkkUdRLGJGY0itRWhe7T+4NQ1J1rMjcf2MuIe7CTmlcgMQEDsnsUI+iy6CqqN8YVpEbXo9EoC2YkmMTajJrjirapR4HvSGua1+87bMj1q4DPYwRedzjESQeUTKqgf7YoBStLqILiWE80VnQgKdRlcWEEVQEFcHGOIZG7N+IftWIjh6J7QUckKMSZWzFcEyuRyldEWhTBO54157kox2gcWK3bkxgEhujnJ5YnGIs2luNYLkePHdjagUMthOSwYz90cZCvCMRYHMEKUbvbpheDHILKrFznUL3uHRiE7sjI0nb5K2oJfQl7VdsYF1eCWmQOy3TDlYJaU1tw2Fhsod+snSzUK0xmSh17xYatbYF1cgyt4BWwOearrneXrQ8X1Uq71Hrf0Wo1JW6phVK90krDdX680Kl2jY9i7JzZ7GaSXyp6RsXHbSNfKmFNeXKjJCUJUlRtjrs3+ZZheKorLjZyuAcPyF32aqvBILEgtyHLpj3IyvnfrN09TvkSuU9il07lcoPqnQ/KQllhdJE+zdwxYqmT1x2DtJjIwguWDbvbxP5teWe5db5dK1yTVZpCGlVkpSkWT+C5YXzWid/vu6sj110XENz6HR8IOZmEAQto+r2GTdlVxgr7BkbL08c2F7aOD0Qsp60tXh+efHkZ2dWTsx+5JQvNDSHTrn9mcOlw8xuEf44pnfuRNfeK7/vf/+f+YPn/jqz/Z3Z9wznmNv5/7r/ALPYw78SkHupAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE3LTA0LTAzVDE5OjU1OjA5LTA0OjAwnkdlngAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxNy0wNC0wM1QxOTo1NTowOS0wNDowMO8a3SIAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC"
                                }
                            ]
                        }
                    }
                })
            }
        ]
    };

    const activeHazardLabel = {
        symbol: {
            type: "text",
            color: "white",
            haloColor: "grey",
            haloSize: 0.5,
            font: {
                family: "Ubuntu Mono",
                size: 12,
                weight: "bold"
            }
        },
        labelPlacement: "above-right",
        labelExpressionInfo: {
            expression: "$feature.hazard_label"
        },
        deconflictionStrategy: 'static',
        maxScale: 0,
        minScale: 4200000
    };

    const activeHazardLayer = new FeatureLayer({
        title: "Wildfires",
        fields: [
            {
                name: 'aoi_id',
                type: 'integer'
            },
            {
                name: 'aoi_name',
                type: "string"

            },
            {
                name: 'contact_email',
                type: "string"
            },
            {
                name: 'contact_name',
                type: "string"
            },
            {
                name: 'created_date',
                type: 'string'
            },
            {
                name: 'hazard_id',
                type: 'integer'
            },
            {
                name: 'hazard_label',
                type: "string"
            },
            {
                name: 'id',
                type: 'oid'
            },
            {
                name: 'latitude',
                type: 'string'
            },
            {
                name: 'location',
                type: 'string'
            },
            {
                name: 'longitude',
                type: 'string'
            },
            {
                name: 'proximity_to_area',
                type: 'string'
            },
            {
                name: 'sent_date',
                type: 'string'
            },
            {
                name: 'status',
                alias: 'Status',
                type: 'string'
            },
            {
                name: 'jurisdiction_code',
                type: 'string'
            }
        ],
        objectIdField: "id",
        labelingInfo: [activeHazardLabel],
        outFields: ["*"],
        geometryType: "point",
        spatialReference: { wkid: 4326 },
        source: [],
        renderer: activeHazardRenderer,
        popupTemplate: {
            title: `Hazard Label: {hazard_label}`,
            content: `<li> Jurisdiction: {jurisdiction_code} </li>
                <li> Status: {status} </li>
                <li> Latitude: {latitude} </li> 
                <li> Longitude: {longitude} </li>
                <li> Proximity to {aoi_name}: {proximity_to_area} Km </li>`
        }
    });

    return activeHazardLayer;
};

export const configureNonActiveHazardLayer = () => {
    const nonActiveHazardRenderer = {
        type: "simple",
        symbol: {
            type: "simple-marker",
            size: 5,
            color: "grey"
        }
    };

    const nonActiveHazardLabel = {
        symbol: {
            type: "text",
            color: "white",
            haloColor: "grey",
            haloSize: 0.5,
            font: {
                family: "Ubuntu Mono",
                size: 12,
                weight: "bold"
            }
        },
        labelPlacement: "above-right",
        labelExpressionInfo: {
            expression: "$feature.hazard_label"
        },
        deconflictionStrategy: 'static',
        maxScale: 0,
        minScale: 4200000
    };

    const nonActiveHazardLayer = new FeatureLayer({
        title: "Extinguished Hazards",
        fields: [
            {
                name: 'aoi_id',
                type: 'integer'
            },
            {
                name: 'aoi_name',
                type: "string"

            },
            {
                name: 'contact_email',
                type: "string"
            },
            {
                name: 'contact_name',
                type: "string"
            },
            {
                name: 'created_date',
                type: 'string'
            },
            {
                name: 'hazard_id',
                type: 'integer'
            },
            {
                name: 'hazard_label',
                type: "string"
            },
            {
                name: 'id',
                type: 'oid'
            },
            {
                name: 'latitude',
                type: 'string'
            },
            {
                name: 'location',
                type: 'string'
            },
            {
                name: 'longitude',
                type: 'string'
            },
            {
                name: 'proximity_to_area',
                type: 'string'
            },
            {
                name: 'sent_date',
                type: 'string'
            },
            {
                name: 'status',
                type: 'string'
            },
            {
                name: 'jurisdiction_code',
                type: 'string'
            }
        ],
        objectIdField: "id",
        labelingInfo: [nonActiveHazardLabel],
        geometryType: "point",
        spatialReference: { wkid: 4326 },
        source: [],
        renderer: nonActiveHazardRenderer,
        visible: false,
        popupTemplate: {
            title: `Hazard Label: {hazard_label}`,
            content: `<li> Status: {status} </li>
                <li> Latitude: {latitude} </li> 
                <li> Longitude: {longitude} </li>
                <li> Proximity to {aoi_name}: {proximity_to_area} Km </li>`
        }
    });

    return nonActiveHazardLayer;
};

export const configureAOILayer = () => {
    const aoiDefaultSymbol = new CIMSymbol({
        data: {
            type: "CIMSymbolReference",
            symbol: {
                "type": "CIMPointSymbol",
                "symbolLayers": [
                    {
                        "type": "CIMVectorMarker",
                        "enable": true,
                        "anchorPointUnits": "Relative",
                        "dominantSizeAxis3D": "Y",
                        "size": 12,
                        "billboardMode3D": "FaceNearPlane",
                        "frame": {
                            "xmin": 0,
                            "ymin": 0,
                            "xmax": 21,
                            "ymax": 21
                        },
                        "markerGraphics": [
                            {
                                "type": "CIMMarkerGraphic",
                                "geometry": {
                                    "rings": [
                                        [
                                            [
                                                11,
                                                16
                                            ],
                                            [
                                                11,
                                                14
                                            ],
                                            [
                                                17,
                                                14
                                            ],
                                            [
                                                17,
                                                4
                                            ],
                                            [
                                                18,
                                                4
                                            ],
                                            [
                                                18,
                                                3
                                            ],
                                            [
                                                3,
                                                3
                                            ],
                                            [
                                                3,
                                                4
                                            ],
                                            [
                                                4,
                                                4
                                            ],
                                            [
                                                4,
                                                14
                                            ],
                                            [
                                                10,
                                                14
                                            ],
                                            [
                                                10,
                                                19
                                            ],
                                            [
                                                11,
                                                19
                                            ],
                                            [
                                                14,
                                                17.5
                                            ],
                                            [
                                                11,
                                                16
                                            ]
                                        ],
                                        [
                                            [
                                                13,
                                                12
                                            ],
                                            [
                                                13,
                                                10
                                            ],
                                            [
                                                16,
                                                10
                                            ],
                                            [
                                                16,
                                                12
                                            ],
                                            [
                                                13,
                                                12
                                            ]
                                        ],
                                        [
                                            [
                                                12,
                                                10
                                            ],
                                            [
                                                12,
                                                12
                                            ],
                                            [
                                                9,
                                                12
                                            ],
                                            [
                                                9,
                                                10
                                            ],
                                            [
                                                12,
                                                10
                                            ]
                                        ],
                                        [
                                            [
                                                8,
                                                10
                                            ],
                                            [
                                                8,
                                                12
                                            ],
                                            [
                                                5,
                                                12
                                            ],
                                            [
                                                5,
                                                10
                                            ],
                                            [
                                                8,
                                                10
                                            ]
                                        ],
                                        [
                                            [
                                                13,
                                                8
                                            ],
                                            [
                                                13,
                                                6
                                            ],
                                            [
                                                16,
                                                6
                                            ],
                                            [
                                                16,
                                                8
                                            ],
                                            [
                                                13,
                                                8
                                            ]
                                        ],
                                        [
                                            [
                                                12,
                                                6
                                            ],
                                            [
                                                12,
                                                8
                                            ],
                                            [
                                                9,
                                                8
                                            ],
                                            [
                                                9,
                                                6
                                            ],
                                            [
                                                12,
                                                6
                                            ]
                                        ],
                                        [
                                            [
                                                8,
                                                6
                                            ],
                                            [
                                                8,
                                                8
                                            ],
                                            [
                                                5,
                                                8
                                            ],
                                            [
                                                5,
                                                6
                                            ],
                                            [
                                                8,
                                                6
                                            ]
                                        ]
                                    ]
                                },
                                "symbol": {
                                    "type": "CIMPolygonSymbol",
                                    "symbolLayers": [
                                        {
                                            "type": "CIMSolidStroke",
                                            "enable": true,
                                            "capStyle": "Round",
                                            "joinStyle": "Round",
                                            "lineStyle3D": "Strip",
                                            "miterLimit": 10,
                                            "width": 0,
                                            "color": [
                                                0,
                                                0,
                                                0,
                                                255
                                            ]
                                        },
                                        {
                                            "type": "CIMSolidFill",
                                            "enable": true,
                                            "color": [
                                                200,
                                                200,
                                                200,
                                                255
                                            ]
                                        }
                                    ]
                                }
                            }
                        ],
                        "scaleSymbolsProportionally": true,
                        "respectFrame": true
                    }
                ]
            }
        }
    });

    const aoiCampSymbol = new CIMSymbol({
        data: {
            type: "CIMSymbolReference",
            symbol: {
                "type": "CIMPointSymbol",
                "symbolLayers": [
                    {
                        "type": "CIMVectorMarker",
                        "enable": true,
                        "anchorPointUnits": "Relative",
                        "dominantSizeAxis3D": "Y",
                        "size": 15,
                        "billboardMode3D": "FaceNearPlane",
                        "frame": {
                            "xmin": 0,
                            "ymin": 0,
                            "xmax": 21,
                            "ymax": 21
                        },
                        "markerGraphics": [
                            {
                                "type": "CIMMarkerGraphic",
                                "geometry": {
                                    "rings": [
                                        [
                                            [
                                                11,
                                                16
                                            ],
                                            [
                                                11,
                                                14
                                            ],
                                            [
                                                17,
                                                14
                                            ],
                                            [
                                                17,
                                                4
                                            ],
                                            [
                                                18,
                                                4
                                            ],
                                            [
                                                18,
                                                3
                                            ],
                                            [
                                                3,
                                                3
                                            ],
                                            [
                                                3,
                                                4
                                            ],
                                            [
                                                4,
                                                4
                                            ],
                                            [
                                                4,
                                                14
                                            ],
                                            [
                                                10,
                                                14
                                            ],
                                            [
                                                10,
                                                19
                                            ],
                                            [
                                                11,
                                                19
                                            ],
                                            [
                                                14,
                                                17.5
                                            ],
                                            [
                                                11,
                                                16
                                            ]
                                        ],
                                        [
                                            [
                                                13,
                                                12
                                            ],
                                            [
                                                13,
                                                10
                                            ],
                                            [
                                                16,
                                                10
                                            ],
                                            [
                                                16,
                                                12
                                            ],
                                            [
                                                13,
                                                12
                                            ]
                                        ],
                                        [
                                            [
                                                12,
                                                10
                                            ],
                                            [
                                                12,
                                                12
                                            ],
                                            [
                                                9,
                                                12
                                            ],
                                            [
                                                9,
                                                10
                                            ],
                                            [
                                                12,
                                                10
                                            ]
                                        ],
                                        [
                                            [
                                                8,
                                                10
                                            ],
                                            [
                                                8,
                                                12
                                            ],
                                            [
                                                5,
                                                12
                                            ],
                                            [
                                                5,
                                                10
                                            ],
                                            [
                                                8,
                                                10
                                            ]
                                        ],
                                        [
                                            [
                                                13,
                                                8
                                            ],
                                            [
                                                13,
                                                6
                                            ],
                                            [
                                                16,
                                                6
                                            ],
                                            [
                                                16,
                                                8
                                            ],
                                            [
                                                13,
                                                8
                                            ]
                                        ],
                                        [
                                            [
                                                12,
                                                6
                                            ],
                                            [
                                                12,
                                                8
                                            ],
                                            [
                                                9,
                                                8
                                            ],
                                            [
                                                9,
                                                6
                                            ],
                                            [
                                                12,
                                                6
                                            ]
                                        ],
                                        [
                                            [
                                                8,
                                                6
                                            ],
                                            [
                                                8,
                                                8
                                            ],
                                            [
                                                5,
                                                8
                                            ],
                                            [
                                                5,
                                                6
                                            ],
                                            [
                                                8,
                                                6
                                            ]
                                        ]
                                    ]
                                },
                                "symbol": {
                                    "type": "CIMPolygonSymbol",
                                    "symbolLayers": [
                                        {
                                            "type": "CIMSolidStroke",
                                            "enable": true,
                                            "capStyle": "Round",
                                            "joinStyle": "Round",
                                            "lineStyle3D": "Strip",
                                            "miterLimit": 10,
                                            "width": 0,
                                            "color": [
                                                0,
                                                0,
                                                0,
                                                255
                                            ]
                                        },
                                        {
                                            "type": "CIMSolidFill",
                                            "enable": true,
                                            "color": [
                                                30,
                                                140,
                                                255,
                                                255
                                            ]
                                        }
                                    ]
                                }
                            }
                        ],
                        "scaleSymbolsProportionally": true,
                        "respectFrame": true
                    }
                ]
            }
        }
    });

    const aoiRenderer = {
        type: "unique-value",
        field: "type",
        defaultSymbol: aoiDefaultSymbol,
        uniqueValueInfos: [
            {
                value: "Camp",
                symbol: aoiCampSymbol,
            }
        ]
    };

    const aoiLabel = {
        symbol: {
            type: "text",
            color: "white",
            haloColor: "black",
            haloSize: 1,
            font: {
                family: "Ubuntu Mono",
                size: 14,
                weight: "bold"
            }
        },
        labelPlacement: "above-right",
        labelExpressionInfo: {
            expression: "$feature.name"
        },
        deconflictionStrategy: 'static',
        maxScale: 0,
        minScale: 25000000
    };

    const aoiLayer = new FeatureLayer({
        title: "Areas of Interest",
        fields: [
            {
                name: 'id',
                type: 'oid'
            },
            {
                name: 'location',
                type: 'string'
            },
            {
                name: 'latitude',
                type: 'string'
            },
            {
                name: 'longitude',
                type: 'string'
            },
            {
                name: 'name',
                type: 'string'
            },
            {
                name: 'type',
                alias: 'Type',
                type: 'string'
            },
            {
                name: 'created_date',
                type: 'string'
            }
        ],
        objectIdField: "id",
        outFields: ["*"],
        labelingInfo: [aoiLabel],
        geometryType: "point",
        spatialReference: { wkid: 4326 },
        source: [],
        renderer: aoiRenderer,
        popupTemplate: {
            title: 'AoI Name: {name}',
            content: `<li> Type: {type} </li>
                    <li> Latitude: {latitude} </li> 
                    <li> Longitude: {longitude} </li>`
        }
    });

    return aoiLayer;
};

export const configureBufferLayer = () => {
    const bufferRenderer = {
        type: "unique-value",
        field: "radius",
        defaultSymbol: {
            type: "simple-line",
            size: 1,
            color: "grey"
        },
        uniqueValueInfos: [
            {
                value: "50",
                symbol: {
                    type: "simple-line",
                    size: 1,
                    color: "orange"
                }
            },
        ]
    };

    const bufferLayer = new FeatureLayer({
        title: "Buffers",
        fields: [
            {
                name: 'id',
                type: 'oid'
            },
            {
                name: 'area',
                type: 'string'
            },
            {
                name: 'aoi_id',
                type: 'integer'
            },
            {
                name: 'radius',
                alias: 'Radius',
                type: 'integer'
            },
        ],
        objectIdField: "id",
        geometryType: "polyline",
        spatialReference: { wkid: 4326 },
        source: [],
        renderer: bufferRenderer,
        popupTemplate: {
            title: '{radius} Km',
        }
    });

    return bufferLayer;
};

export const configureHazardExtentLayer = () => {
    const extentRenderer = {
        type: "unique-value",
        field: "hazard_status",
        defaultSymbol: {
            type: "simple-line",
            size: 5,
            color: [20, 20, 20]
        },
        uniqueValueInfos: [
            {
                value: "Out of Control",
                symbol: {
                    type: "simple-line",
                    size: 5,
                    color: "red"
                }
            },
            {
                value: "Being Held",
                symbol: {
                    type: "simple-line",
                    size: 5,
                    color: "yellow"
                }
            },
            {
                value: "Under Control",
                symbol: {
                    type: "simple-line",
                    size: 5,
                    color: [91, 194, 54]
                }
            },
            {
                value: "Assistance Started",
                symbol: {
                    type: "simple-line",
                    size: 5,
                    color: [174, 198, 207]
                }
            },
            {
                value: "New",
                symbol: {
                    type: "simple-line",
                    size: 5,
                    color: "orange"
                }
            },
            {
                value: "Fire of Note",
                symbol: {
                    type: "simple-line",
                    size: 5,
                    color: "red"
                }
            }
        ]
    };

    const extentLayer = new FeatureLayer({
        title: "Fire Perimeters",
        fields: [
            {
                name: 'id',
                type: 'oid'
            },
            {
                name: 'hazard_label',
                type: 'string'
            },
            {
                name: 'hazard_type',
                type: 'string'
            },
            {
                name: 'hazard_status',
                alias: 'Hazard Status',
                type: 'string'
            },
            {
                name: 'size_class',
                type: 'string'
            },
            {
                name: 'hazard_extent',
                type: 'string'
            },
        ],
        objectIdField: "id",
        geometryType: "polygon",
        spatialReference: { wkid: 4326 },
        source: [],
        renderer: extentRenderer,
        popupTemplate: {
            title: `Fire Perimeter`,
            content: `<li> Status: {hazard_status} </li>
                    <li> Hazard Label: {hazard_label} </li> 
                    <li> Size Class: {size_class} </li> 
                    <li> Hazard Extent: {hazard_extent} H </li>`
        }
    });

    return extentLayer;
};

export const configureEvacuationExtentLayer = () => {
    const evacuationRenderer = {
        type: "unique-value",
        field: "status",
        defaultSymbol: {
            type: "simple-line",
            size: 1,
            color: "grey"
        },
        uniqueValueInfos: [
            {
                value: "Order",
                symbol: {
                    type: "simple-line",
                    size: 1,
                    color: "red"
                },
            },
            {
                value: "Alert",
                symbol: {
                    type: "simple-line",
                    size: 1,
                    color: "orange"
                },
            }
        ]
    };

    const evacuationLayer = new FeatureLayer({
        title: "Evacuation Zones",
        fields: [
            {
                name: 'id',
                type: 'oid'
            },
            {
                name: 'oaa_id',
                type: 'string'
            },
            {
                name: 'hazard_type',
                type: 'string'
            },
            {
                name: 'hazard_label',
                type: 'string'
            },
            {
                name: 'status',
                alias: 'Status',
                type: 'string'
            },
            {
                name: 'issuing_agency',
                type: 'string'
            },
            {
                name: 'area',
                type: 'string'
            },
        ],
        objectIdField: "id",
        geometryType: "polygon",
        spatialReference: { wkid: 3857 },
        source: [],
        renderer: evacuationRenderer,
        popupTemplate: {
            title: `Evacuation Orders and Alerts`,
            content: `<li> Status: {status} </li>
                    <li> Event Label: {hazard_label} </li> 
                    <li> Event Type: {hazard_type} </li> 
                    <li> Issuing Agency: {issuing_agency} </li>`
        }
    });

    return evacuationLayer;
};
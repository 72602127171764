import Expand from '@arcgis/core/widgets/Expand';
import Legend from '@arcgis/core/widgets/Legend';
import LayerList from "@arcgis/core/widgets/LayerList";
import Search from "@arcgis/core/widgets/Search";
import BasemapGallery from '@arcgis/core/widgets/BasemapGallery'

import esriConfig from "@arcgis/core/config";

export const configureWidget = (view, alertLayer, alertOutLayer, aoiLayer) => {
    const basemapGallery = new BasemapGallery({
        view,
        source: {
            query: {
                title: '"World Basemaps for Developers" AND owner:esri'
            }
        }
    });

    const toggleTableBtn = document.getElementById('toggle-table-btn');
    const tableContainer = document.getElementById('feature-table-container');
    const mainMapContainer = document.getElementById('main-map-container');

    toggleTableBtn.onclick = () => {
        if (tableContainer.style.display === 'block') {
            tableContainer.style.display = 'none';
            mainMapContainer.setAttribute('style', 'height: 95%', 'bottom: 0');

        } else {
            tableContainer.style.display = 'block';
            mainMapContainer.setAttribute('style', 'height: 65%', 'bottom: 30%');
        }
    };

    const searchBar = new Search({
        view,
        includeDefaultSources: false,
        sources: [
            {
                layer: alertLayer,
                searchFields: ['hazard_label', 'latitude', 'longitude'],
                suggestionTemplate: "Hazard Label: {hazard_label}, Location: {longitude}, {latitude}",
                exactMatch: false,
                outFields: ["*"],
                name: "Hazards",
                placeholder: "Find hazard"
            },
            {
                layer: alertOutLayer,
                searchFields: ['hazard_label', 'latitude', 'longitude'],
                suggestionTemplate: "Hazard Label: {hazard_label}, Location: {longitude}, {latitude}",
                exactMatch: false,
                outFields: ["*"],
                name: "Extinguished Hazards",
                placeholder: "Find hazard",
            },
            {
                layer: aoiLayer,
                searchFields: ['name', 'latitude', 'longitude'],
                suggestionTemplate: "AoI: {name}, Location: {longitude}, {latitude}",
                exactMatch: false,
                outFields: ["*"],
                name: "Areas of Interest",
                placeholder: "Find area of interest",
            },
            {
                name: "ArcGIS World Geocoding Service",
                placeholder: "Find address or place",
                apiKey: esriConfig.apiKey,
                singleLineFieldName: "SingleLine",
                locator:
                    "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer"
            }
        ]
    });

    const legend = new Legend({
        view,
    });

    const layerList = new LayerList({
        view,
    });

    const basemapExpand = new Expand({
        view,
        content: basemapGallery,
        expanded: false,
        expandTooltip: 'Change Basemap',
        group: "top-right"
    });

    const legendExpand = new Expand({
        view,
        content: legend,
        expanded: false,
        expandTooltip: 'Legend',
        group: "top-right"
    });

    const layersExpand = new Expand({
        view,
        content: layerList,
        expanded: false,
        expandTooltip: 'Toggle Layers',
        group: "top-right"
    });

    view.ui.add([searchBar, basemapExpand, legendExpand, layersExpand, toggleTableBtn], "top-right");
};